import { tns } from 'tiny-slider';

//======== tiny slider for carousel
if (document.querySelectorAll('.header-style-3-active').length > 0) {
	tns({
		autoplay: true,
		autoplayButtonOutput: false,
		mouseDrag: true,
		gutter: 0,
		container: ".header-style-3-active",
		center: true,
		nav: true,
		controls: false,
		speed: 400,
		controlsText: [
			'<i class="lni lni-arrow-left-circle"></i>',
			'<i class="lni lni-arrow-right-circle"></i>',
		],
		responsive: {
			0: {
				items: 1,
			},
		},
	});
}

//======== tiny slider for three-column-carousel
if (document.querySelectorAll('.three-column-carousel').length > 0) {
	tns({
		mouseDrag: true,
		container: ".three-column-carousel",
		nav: false,
		controls: true,
		center: true,
		speed: 400,
		controlsText: [
			'<i class="mdi mdi-chevron-left"></i>',
			'<i class="mdi mdi-chevron-right"></i>',
		],
		responsive: {
			0: {
				items: 1,
			},
			575: {
				items: 2,
			},
			992: {
				items: 3,
			},
		},
	});
}

//======== tiny slider for two-column-carousel
if (document.querySelectorAll('.two-column-carousel').length > 0) {
	tns({
		mouseDrag: true,
		container: ".two-column-carousel",
		nav: true,
		controls: false,
		// center: true,
		speed: 400,
		responsive: {
			0: {
				items: 1,
			},
			992: {
				items: 2,
			},
		},
	});
}

//======== tiny slider for testimonial-one
if (document.querySelectorAll('.testimonial-style-1-active').length > 0) {
	tns({
		mouseDrag: true,
		container: ".testimonial-style-1-active",
		nav: true,
		controls: false,
		speed: 400,
		responsive: {
			0: {
				items: 1,
			},
			768: {
				items: 2,
			},
			992: {
				items: 3,
			},
		},
	});
}

// ========= main-menu 6  activation
let menuToggle6 = document.querySelector('.menu-style-6 .menu-toggle');
if (menuToggle6) {
	menuToggle6.addEventListener('click', () => {
		menuToggle6.classList.toggle('active');
	});
}

